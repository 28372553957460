@import '~antd/dist/antd.less';

body {
  background-color: #f0f2f5;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root,
.layout {
  height: 100%;
}
.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
.contentlayout {
  padding: 20px 50px;
  @media only screen and (max-width: 800px) {
    padding: 12px;
  }
}

.logo {
  float: left;
  @media only screen and (max-width: 400px) {
    display: none;
  }
}

.mobileLogo {
  float: left;
  @media only screen and (min-width: 400px) {
    display: none;
  }
}

.header {
  background-color: #fff;
  height: 66px;
  border-bottom: 1px solid #f0f0f0;
  @media only screen and (max-width: 800px) {
    padding: 0px 12px !important;
  }
}

.mainNav {
  float: right;
}

.logoHolder {
  height: 100%;
  display: inline-flex;
  align-items: center;
}

.ant-form-item-explain {
  padding: 20px 0px;
}

.hand {
  font-size: 32px;
  display: inline-block;
  cursor: pointer;
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: 1; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
}

.eventScene .roleDropDownLabel {
  color: #999;
  font-weight: 600;
}

.ant-form-item-explain.ant-form-item-explain-error {
  padding: 0 !important;
  padding-top: 4px !important;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

@primary-color: #428e3e;@table-selected-row-bg: #fbfbfb;