.isNew {
  background-color: #f0f9fe;
}

.editable-cell-value-wrap {
  padding-right: 11px;
  min-width: 30px;
  height: 30px;
  border: 1px solid transparent;
  width: 100%;
}

.editable-cell-value-wrap:hover {
  padding-right: 24;
  border-color: #ccc;
}

@primary-color: #428e3e;@table-selected-row-bg: #fbfbfb;