.mobileNotes {
  background-color: #222;
  padding: 1em;
  height: 250px;
  overflow-y: scroll;
}

.desktopNotes {
  display: none;
}

.desktopBarHolder {
  height: unset;
}

.slideButtons {
  transition: all 0.15s !important;
}
.slideButtons:active {
  background-color: #005f45 !important;
}

.slideButtons.previousDesktop:active,
.slideButtons.previousMobile:active {
  background-color: #8d113e !important;
}

.slideButtons.previousDesktop {
  display: none;
}

.notes::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 20px;
}
.notes::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  min-height: 40px;
}

@media (min-width: 800px) {
  .mobileNotes {
    display: none;
  }
  .desktopNotes {
    display: block;
    background-color: #222;
    padding: 1em;
    max-height: 50vh;
    overflow-y: scroll;
    border-radius: 8px;
    height: 100%;
    height: 158px;
    resize: vertical;
    overflow: auto;
  }

  .desktopNotes.includesHTML {
    background-color: #fff;
    border: 1px solid #222;
  }

  .desktopBarHolder {
    /* height: 162px; */
  }
  .slideButtons.previousDesktop {
    display: block;
  }
  .slideButtons.previousMobile {
    display: none;
  }
  .slideButtons.nextDesktop {
    /* margin-top: 20px; */
  }
}
